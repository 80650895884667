const config = {
    apiKey: "AIzaSyBsqMt2jCTbf49GxRp-K-R3C46TMvC2o9Q",
    authDomain: "sudokujs-7c3fa.firebaseapp.com",
    projectId: "sudokujs-7c3fa",
    storageBucket: "sudokujs-7c3fa.appspot.com",
    messagingSenderId: "433749756849",
    appId: "1:433749756849:web:eb2a196374a73fa6e10b90",
    measurementId: "G-P9F2JTEF3W"
  };

  export default config;